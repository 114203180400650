// https://convertkit.com/api/v3/

import service from "@utils/apiService";

import config from "@utils/config";

// build subscribe
export const subscribe = async (email: string) => {
  const url = `https://api.convertkit.com/v3/forms/${config.CONVERTKIT_FORM_ID}/subscribe`;
  console.log({
    email,
    API_KEY: config.CONVERTKIT_API_KEY,
    formId: config.CONVERTKIT_FORM_ID,
  });
  const response = await service.post(url, {
    body: {
      api_key: config.CONVERTKIT_API_KEY,
      email: email,
    },
  });

  return response;
};
