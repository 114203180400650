"use client";
import React from "react";
import styles from "./Banner.module.scss";
import cx from "classnames";

import SubscribeForm from "@components/common/SubscribeForm";

const View = () => {
  return (
    <section data-scroll-section className={cx("inner", styles.section)}>
      <span className={styles.title}>
        Tối ưu, tập trung, hiệu quả.
        <br />
        Để tận hưởng cuộc sống.
      </span>
      <SubscribeForm />
    </section>
  );
};

export default View;
