import React from "react";

export const useWillMount = (cb) => {
  const willMount = React.useRef(true);
  if (willMount.current) {
    console.log("willMount");
    cb();
  }
  willMount.current = false;
};

export default useWillMount;
