// import { createContext, useContext, ReactNode } from "react";
// import { AppFormContext, AppFormProps } from "@type/provider/Form";
// export { default as Yup } from "@utils/yup";
// export { useFormik } from "formik";

// export const FormContext = createContext<AppFormContext>({});

// interface TypeUseFormContext {
//   (): AppFormContext;
// }

// const FormProvider = ({ children, form }: AppFormProps): ReactNode => {
//   return <FormContext.Provider value={form}>{children}</FormContext.Provider>;
// };
// export const useFormContext: TypeUseFormContext = () => {
//   return useContext(FormContext);
// };
// export default FormProvider;

import { createContext, useContext, ReactNode } from "react";
import { AppFormContext, AppFormProps } from "@type/provider/Form";
import { FormikProvider } from "formik";
import { isFunction } from "lodash";
export { default as Yup } from "utils/yup";
export { useFormik, useFormikContext as useFormContext } from "formik";
export const FormContext = createContext<AppFormContext>({});

interface TypeUseFormContext {
  (): AppFormContext;
}

// const FormProvider = ({ children, form }: AppFormProps): ReactNode => {
//   return <FormContext.Provider value={form}>{children}</FormContext.Provider>;
// };
const FormProvider = ({ children, form }) => {
  return (
    <FormikProvider value={form}>
      {isFunction(children) ? children(form) : children}
    </FormikProvider>
  );
};

export default FormProvider;

// export const useFormContext: TypeUseFormContext = () => {
//   return useContext(FormContext);
// };
// export default FormProvider;
