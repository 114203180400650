"use client";
import React from "react";
import { Field } from "formik";
import styles from "./Input.module.scss";
import { isEmpty, get } from "@utils/lodash";

import cx from "classnames";
const View = ({
  className,
  name,
  validate,
  fast,
  placeholder,
  required,
  onChange,
  onBlur,
  disableError = false,
  theme = "light",
  type = "text",
  classNameError,
  pattern = undefined,
}: any) => {
  const ref: any = React.useRef({});
  const onChangeHandle = (event: any) => {
    const value = get(event, "target.value");
    ref.current.form && ref.current.form.setFieldValue(name, value);
    onChange && onChange(value);
  };
  const onBlurHandle = (e: any) => {
    ref.current.form && ref.current.form.setFieldTouched(name);
    onBlur && onBlur(e);
  };
  return (
    <Field name={name} validate={validate} fast={fast}>
      {({ field: { value }, form, meta }: any) => {
        ref.current.form = form;
        return (
          <div
            className={cx(styles.group, {
              [styles.valid]: value === 0 || !isEmpty(value),
            })}
          >
            <input
              type={type}
              value={value}
              onChange={onChangeHandle}
              onBlur={onBlurHandle}
              aria-label={name}
              title=""
              className={cx({ [styles.dark]: theme === "dark" }, className)}
              pattern={pattern}
            />

            <label className={cx({ [styles.dark]: theme === "dark" })}>
              {placeholder}
              {required && <span className={styles.required}>*</span>}
            </label>
            {!disableError && meta.touched && meta.error && (
              <div className={cx(styles.error, classNameError)}>
                {meta.error}
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
};
export default View;
