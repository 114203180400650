"use client";
import React, { useState } from "react";
import FormProvider, { useFormik } from "@providers/FormProvider";
import Input from "@components/common/Formik/Input";
import Button from "@components/common/Button";
import Show from "@components/common/Show";
import Yup from "@utils/yup";
import { EMAIL } from "@utils/validate";
import { subscribe } from "@utils/convertkit";
import styles from "./SubscribeForm.module.scss";
import cx from "classnames";
import { useRouteContext } from "@providers/RouteProvider";
import { debounce } from "lodash";

const SubscribeForm: React.FC = () => {
  const { router } = useRouteContext();
  const [isSubmitting, $isSubmitting] = useState(false);
  const [submitStatus, $submitStatus] = useState<"idle" | "success" | "error">(
    "idle"
  );

  const form = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      $isSubmitting(true);
      try {
        const response = await subscribe(values.email);
        if (response.statusCode === 200) {
          $submitStatus("success");
          debounce(() => {
            router.push("cam-on");
          }, 2000)();
        } else {
          $submitStatus("error");
        }
      } catch (error) {
        console.error("Lỗi khi đăng ký:", error);
        $submitStatus("error");
      } finally {
        $isSubmitting(false);
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Bạn chưa nhập email")
        .matches(EMAIL, "Email chưa hợp lệ"),
    }),
  });
  return (
    <FormProvider form={form}>
      <form
        className={cx(styles.subscribeForm, "flex flex-row items-center mt-4")}
      >
        <div className="mr-4">
          <Input
            name="email"
            placeholder="Email của bạn"
            className={cx(styles.emailInput)}
            classNameError={styles.error}
            disableError={true}
          />
        </div>
        <Button
          type="submit"
          label={isSubmitting ? "Đang xử lý..." : "Đăng ký"}
          className={cx(styles.subscribeButton)}
          onClick={form.handleSubmit}
          disabled={isSubmitting}
        />
      </form>
      <div className={cx("relative", styles.notification)}>
        <Show.When isTrue={submitStatus === "success"}>
          <p className={cx("text-green-500 mt-2", styles.info)}>
            Thành công, kiểm tra email của bạn!
          </p>
        </Show.When>

        <Show.When
          isTrue={
            submitStatus === "error" ||
            (form.touched.email && form.errors.email)
          }
        >
          <p className={cx("text-red-500 mt-2", styles.info)}>
            Kiểm tra lại email của bạn và thử lại.
          </p>
        </Show.When>
      </div>
      {/* {submitStatus === "error" && (
        
      )} */}
    </FormProvider>
  );
};

export default SubscribeForm;
