"use client";
import React from "react";
import cx from "classnames";
import styles from "./FeaturePosts.module.scss";
import CardVertical from "@components/common/CardVertical";
import Show from "@components/common/Show";
import Button from "@components/common/Button";
import { usePageContext } from "@providers/PageProvider";
import usePagination from "@hooks/usePagination";
import useWillMount from "@hooks/useWillMount";
import { map, get, debounce } from "lodash";
import helper from "@utils/helper";

const View = () => {
  const { pageData } = usePageContext();
  const pagination = usePagination({
    initialParams: {
      type: "infinity",
      page: 0,
      limit: 6,
    },
    loader: async (payload) => {
      const page = get(payload, "page", 0);
      const limit = get(payload, "limit", 3);
      const data = get(pageData, "articles") || [];
      const startIndex = page * limit;
      const endIndex = startIndex + limit;
      const paginatedData = data.slice(startIndex, endIndex);
      const articles = map(paginatedData, helper.formatArticle);
      if (!payload.isFast) await helper.sleep(1500);
      return {
        data: articles,
        total: get(pageData, "articles").length,
      };
    },
  });
  useWillMount(() => {
    pagination.submit({ isFast: true });
  });
  // const data = get(pageData, "articles");
  // const articles = map(data, helper.formatArticle);
  //
  return (
    <section data-scroll-section className={cx("inner", styles.section)}>
      <div className="label-title">Blogs</div>
      <div className={styles.box}>
        {map(pagination.items, (item, index) => (
          <CardVertical key={`card_${index}`} {...item} />
        ))}
      </div>
      <div className="flex justify-center mt-8">
        <Show.When isTrue={pagination.hasNextPage}>
          <Button
            loading={pagination.isLoading}
            onClick={() => {
              pagination.onNextPage();
            }}
            size="small"
            label="Xem Thêm"
          />
        </Show.When>
      </div>
    </section>
  );
};
export default View;
