import React from "react";
import {
  isNumber,
  get,
  isFunction,
  isEqual,
  isEmpty,
  round,
  assign,
  isString,
} from "lodash";

interface LoaderFunc {
  (opts: { page: number; limit: number; [key: string]: any }): Promise<any>;
}
interface TypeInitialParams {
  type?: string; // infinity | ""
  page?: number;
  limit?: number;
  [key: string]: any;
}
interface TypeConfig {
  initialParams?: TypeInitialParams;
  loader?: LoaderFunc;
  onSearch?: (key: string, value: any) => void;
  onChangeFilter?: (filters: object, sorter: object, columns?: any) => void;
}
interface TypeUsePaginationReturn {
  items: any[];
  page: number;
  limit: number;
  total: number;
  isLoading: boolean;
  errors?: any;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  onLoadPage: (page: number) => void;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onChangePage: (page: number) => void;
  onSearch: (key: string, value: any) => void; // custom action
  onReload: () => void;
  onRefresh: () => void;
  submit: (payload: { [key: string]: any }) => void;
}
interface TypeUsePagination {
  (config?: TypeConfig): TypeUsePaginationReturn;
}
/**
 *
 * @param { initialParams: <PlainObject>, loader: <AsyncFunction> } config
 * @returns { items, page, total, isLoading, errors, hasPreviousPage, hasNextPage, onLoadPage, onPreviousPage, onNextPage, onChangePage } PaginationModel
 */

const usePagination: TypeUsePagination = (config: TypeConfig = {}) => {
  const ref: any = React.useRef({});
  const [limit, $limit] = React.useState(
    get(config, "initialParams.limit", 10)
  );
  const type = get(config, "initialParams.type", "");
  const [items, $items] = React.useState([]);
  const [page, $page] = React.useState(get(config, "initialParams.page", 0));
  const [total, $total] = React.useState(0);
  const [isLoading, $isLoading] = React.useState(
    get(config, "initialParams.isLoading") ?? true
  );
  const [errors, $errors] = React.useState(null);
  assign(ref.current, {
    items,
    $items,
    page,
    $page,
    limit,
    $limit,
    total,
    $total,
    isLoading,
    $isLoading,
    errors,
    $errors,
  });
  const dataLoader = async (opts?: any) => {
    try {
      if (!isLoading) {
        $isLoading(true);
      }
      const { loader } = config;
      const { page: oPage, limit: oLimit, reload: oReload, ...opt } = opts;

      if (isFunction(loader)) {
        const reload = oReload ?? false;
        const page = oPage ?? ref.current.page;
        const limit = oLimit ?? ref.current.limit;
        const payload = {
          page,
          limit,
          ...opt,
        };
        const res = await loader(payload);
        const total = get(res, "total", 0);
        if (!isEqual(ref.current.total, total)) {
          ref.current.$total(total);
        }
        if (!isEqual(ref.current.page, page)) {
          ref.current.$page(page);
        }
        if (!isEqual(ref.current.limit, limit)) {
          ref.current.$limit(limit);
        }
        const newErrors = get(res, "errors", null);
        if (!isEmpty(newErrors)) {
          ref.current.$errors(newErrors);
        }
        const newItems = get(res, "data", []);

        if (type === "infinity" && !reload) {
          ref.current.$items([...ref.current.items, ...newItems]);
        } else {
          ref.current.$items(newItems);
        }
        $isLoading(false);
      }
    } catch (errors: any) {
      $errors(errors);
      console.log({ errors });
      $isLoading(false);
    }
  };
  const hasNextPage =
    ref.current.page + 1 < round(total / ref.current.limit + 0.5, 0);
  const hasPreviousPage = ref.current.page > 0;
  const onLoadPage = (page: number) => {
    const limit = get(config, "limit") || ref.current.limit;
    dataLoader({ page, limit });
  };
  const onNextPage = () => {
    console.log("@PAGINATION onNextPage");
    if (hasNextPage) {
      onLoadPage(ref.current.page + 1);
    }
  };
  const onPreviousPage = () => {
    console.log("@PAGINATION onPreviousPage");
    if (hasPreviousPage) {
      onLoadPage(ref.current.page - 1);
    }
  };
  const onChangePage = (page: number) => {
    console.log("@PAGINATION onChangePage");

    if (page > ref.current.page) {
      onNextPage();
    } else if (page < ref.current.page) {
      onPreviousPage();
    }
  };

  const onSearch = (key: string, value: any) => {
    console.log("@PAGINATION onSearch", { key, value });
    dataLoader({ [key]: value, page: 0, reload: true });
  };
  const onReload = () => {
    console.log("@PAGINATION onReload");
    ref.current.$page(0);
    const limit = get(config, "limit") || ref.current.limit;
    dataLoader({ page: 0, limit, reload: true });
  };
  const onRefresh = () => {
    console.log("@PAGINATION onRefresh");
    dataLoader();
  };
  const submit = (payload: { [key: string]: any }) => {
    console.log("@PAGINATION submit");
    dataLoader({ page: 0, reload: true, ...payload });
  };
  return {
    items,
    page,
    limit,
    total,
    isLoading,
    errors,
    hasPreviousPage,
    hasNextPage,
    onLoadPage,
    onPreviousPage,
    onNextPage,
    onChangePage,
    onSearch,
    onReload,
    onRefresh,
    submit,
  };
};

export default usePagination;
